@import "../abstracts/colors";

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .form,
  .image {
    width: 50%;
    height: 100%;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    .logoRow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block: 30px;
      p {
        color: $cyan_500;
        font-size: 24px;
      }
    }
    .header {
      text-align: center;
      font-weight: 400;
      color: $black_basic;
      font-size: 30px;
      margin-bottom: 45px;
    }
    .formWrapper {
      flex: 1;
      display: flex;
      width: 60%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .forgetPassword {
        cursor: pointer;
        font-weight: 300;
        color: $orange_basic;
        font-size: 20px;
        align-self: flex-end;
        margin-bottom: 10px;
        align-self: flex-end;
      }
    }
    .setPasswordWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      margin-bottom: 30px;
      .setPassword {
        color: $orange_basic;
        font-size: 20px;
        font-weight: 300;
        span {
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    .form {
      width: 100%;
      .flexSection {
        width: 70%;
      }
    }
    .image {
      display: none;
    }
  }
}
