@import "../abstracts/colors";

.tableContainer{
  overflow-x:auto;
}
table {
  width: 100%;
}
thead {
  border:  1px solid $grey_20;
  border-left: 0;
  border-right: 0;
  color: $light_grey;
}

td, th {
  font-weight: normal;
  text-align: left;
  padding: 12px;
  font-size: 14px;
    // cursor: pointer;
  }
tr:hover img{
  opacity: 1;
}

tr th img{
  padding-inline: 10px;
  opacity: 0;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: $grey_20;
}
table span{
  padding-inline: 10px;
  cursor: pointer;
}
.status, .editBtn{
  color: $cyan_400;
}
.deleteBtn{
  color: $red_error;
}


  