@import "../abstracts/colors";

.DraftButton {
  cursor: pointer;
  width: 100%;
  margin-top: 2em;
  background-color: transparent;
  color: $orange_basic;
  appearance: none;
  border-radius: 18px;
  border: 1px solid $orange_basic;
  outline: none;
  padding-block: 15px;
  p {
    font-size: 20px;
    margin: 0;
  }
}