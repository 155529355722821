@import "../abstracts/colors";

.wraper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.inputWrapper {
    width: 100%;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px dashed $grey_500;
    border-radius: 18px;
    padding-top: 0;
    padding-inline: 15px;
    margin: .6em 0;
}
.placeholder{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font: 16px;
    color:$light_grey;
}
input{
    width: 100%;
}
.imgIcon{
    width: 72px;
    height: 62px;
    background: #CCE9EE;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}
.image{
    max-width: 100%;
    height: 100%;
}

button{
    border: 1px solid $grey_500;
    color: $light_grey;
    border-radius: 8px;
    padding: 10px 25px;
    cursor: pointer;
    background-color: transparent;
}


@media (max-width: 1089px) {
    .imgIcon{
        display:none;
    }
    .placeholder p{
        font-size: 14px;
    }
    button{
        padding: 10px 15px;
    }
  }
  
  @media (max-width: 817px) {
    .imgIcon{
        display:none;
    }
    .placeholder p{
        font-size: 14px;
    }
    button{
        padding: 10px 15px;
    }
  }