@import "../abstracts/colors";

.cancelButton {
  cursor: pointer;
  margin: .5em ;
  padding-block: 20px;
  background-color: transparent;
  color: $orange_basic;
  appearance: none;
  border: none;
  outline: none;

  p {
    font-size: 20px;
  }
}
.disabled {
  opacity: 0.6;
}
