@import "../abstracts/colors";

.wrapper {
  width: 20%;
  background-color: $cyan_basic;
  padding-inline: 10px;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  z-index: 99999;

  .logoRow {
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 25px 0;
    img {
      width: 45%;
    }
    p {
      color: $white_basic;
      font-size: 1.1vw;
      padding-top: 10px;
    }
  }
  .navWrapper {
    flex: 1;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    padding-left: 0;
    width: 100%;
    .navRow {
      cursor: pointer;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      &:hover {
        opacity: 0.75;
      }
      .adsNavRow {
        flex: auto;
      }
      img {
        margin-inline-end: 25px;
      }
      span {
        color: $white_basic;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  .selectUl {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-inline-end: 15px;
  }

  .navOptions {
    // justify-content: flex-end !important;
  }
  .navOptions span {
    font-weight: normal !important;
  }
  .optionsFirstSpan {
    margin-inline-end: 20px;
  }

  .footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    padding-left: 0;
    width: 100%;
    list-style-type: none;

    .footerContainer {
      display: block;
    }

    .footerRow {
      cursor: pointer;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      &:hover {
        opacity: 0.75;
      }
      img {
        margin-inline-end: 25px;
      }
      span {
        color: $white_basic;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

.active {
  background-color: $cyan_400;
  border-radius: 18px;
}

@media (max-width: 1261px) {
  .wrapper {
    width: 10%;
    padding: 5px;
    position: initial;
    .logoRow {
      img {
        width: 100%;
      }
      p {
        display: none;
      }
    }
    .navWrapper {
      .navRow {
        align-self: center;
        justify-content: center;

        img {
          margin: 0;
        }
        span {
          display: none;
        }
      }
    }
    .footer {
      justify-content: flex-end;
      .footerRow {
        align-self: center;
        justify-content: center;
        width: 75%;
        img {
          margin: 0;
        }
        span {
          display: none;
        }
      }
    }
  }

  .selectUl {
    position: absolute;
    width: fit-content !important;
    background-color: #00a9c3;
    left: 9%;
    padding: 15px;
    top: 32%;
    border-radius: 10px;
  }
}
