@import "../abstracts/colors";

.wrapper {
  display: flex;
  width: 30vw;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    width: 100%;
    font-weight: 500;
    color: $black_basic;
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 1089px) {
  .wrapper {
    width: 40vw;
  }
}

@media (max-width: 817px) {
  .wrapper {
    width: 55vw;
  }
}
