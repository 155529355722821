@import "../abstracts/colors";

.otpWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 60px;
  .inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-block-end: 30px;
    .otpInput {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      border: 1px solid $grey_200;
      margin-inline-end: 10px;
      font-size: 30px;
      padding: 25px;
      appearance: none;
      outline: none;
      color: $black_basic;
    }

    .otpInput:focus {
      border: 1px solid black;
    }
  }
  .errorMsg {
    width: 100%;
    text-align: left;
    color: $red_error;
    display: block;
    margin-block-end: 60px;
  }
  .timer {
    color: $black_basic;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .resend {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    color: $black_basic;
    margin-bottom: 60px;
  }
}
.modalContent {
  border-radius: 40px;
  width: 80%;
  margin: auto;
  padding: 40px;
  position: relative;
  .modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .floatingBgImage {
      position: absolute;
      top: -40px;
      right: -40px;
    }
    .imageWrapper {
      z-index: 5;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-inline-end: 24px;
      margin-bottom: 50px;
    }
    .modalText {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 50px;
    }
  }
}
