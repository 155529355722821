@import "../abstracts/colors";

.wraper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
 
.inputWrapper {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

input{
    border: 1px solid $grey_500;
    border-radius: 18px;
    width: 100%;
    height: 50px;
    display: flex;
    padding-inline: 15px;
    margin: .6em 0;
    background-color: transparent;
    cursor: auto;
}
input:focus{
    outline: none ;
    border-color: $light_grey;
}

input::placeholder{
    font: normal normal normal 16px/19px Segoe UI;
    color:$light_grey;
}
}
}
.titleLabel{
    align-content: flex-start;
    width: 100%;
label{
    font: 22px ;
    color: $black_basic;
    padding-top: 10px;
}
}
input:focus{
    border-color: $grey_basic;
}

.errorMsg {
    color: $red_error;
    display: block;
}