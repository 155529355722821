$black_basic: #333333;
$black_dark:#060606;
$white_basic: #ffffff;
$grey_basic : #EFEFEF;
$grey_10: #f8f8f8;
$grey_20: #F3F2F2;
$grey_50: #f1f1f1;
$grey_100: #e8e8e8;
$grey_200: #d5d5d5;
$grey_300 : #EEE;
$grey_400 : #CCC;
$grey_500: #acacac;
$light_grey: #707070;
$dark_grey: #A6A6A63F;
$light_cyan:#CCE9EE;
$cyan_300:#197989;
$cyan_400: #1c8697;
$cyan_500: #005562;
$cyan_basic: #00a9c3;
$orange_basic: #f37021;
$red_error: #ff0000;