@import "../abstracts/colors";

.wraper{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    width: 100%;
    background-color: transparent;
    margin-bottom: 10px;
 
.dropDown {
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid $grey_500;
    border-radius: 18px;
    padding: 10px 0;
    margin: .6em 0;
    cursor: pointer;

.disabledOption{
    padding:0 10px;
    margin: 0;
    color:$light_grey;
    font-size: 16px;
}
.selectUl{
    width: 100%;
    list-style: none;
    flex-direction: column;
    background-color: $white_basic;
    box-shadow: 0px 15px 30px $dark_grey;
    border: 1px solid $grey_basic;
    border-radius: 18px;
    display: block;
    position: absolute;
    max-height: 250px;
    overflow: auto;
    top: 120%;
    z-index: 99;
}

li{
    font: normal normal normal 18px Helvetica;
    color: $black_basic;
    padding: 5px 20px;
    margin: .5em;
    width: 90%;
}
.options:hover{
    color: $cyan_400;
    background-color: $grey_50 ;
    cursor: pointer;
    border-radius: 12px;
}
}
}
.iconDropDown{
    padding-left: 15px;
}
label{
    font: normal normal normal 22px ;
    color: $black_basic;
}
.arrowDropDown{
    padding: 0 15px;
}
.dropDownSelections{
    flex-grow: 1;
    display: flex;
}   
.calendarElement{
    position: absolute;
    top: 56px;
    left: 1px;  
}
