@import "../abstracts/colors";

.wrapper {
  display: flex;
  width: 100%;
  background-color: $grey_10;
  height: 100%;
  margin:0; 
  padding:0;
  position: relative;
}


