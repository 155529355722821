@import "../abstracts/colors";


.wrapper{
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin: 20px;
}

.header{
    display: flex;
    flex-direction: column;
h2{
    margin-bottom: 0;
}
p{
    font-size: 16px;
    color: #333;
}
}
.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
.filter{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.filterParag{
    color: $grey_500;
    width: max-content;
}
.dropdown{
    background-color: $light_cyan;
    margin: 0 0.6em 0.6em 0.6em !important;
    width: 230px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 15px !important;
    border: 0 !important;
}

.dropdown ul{
    padding: 0 !important;
    font-size: 15px !important;
}
.dropdown ul li{
    padding:  7px !important;
    margin: 10px !important;
}

.addBtn{
    margin: 0;
    padding-block: 0;
    height: 50px;
    font-size: 20px;
    margin-left: 10px;
    padding-inline: 35px ;
    border-radius: 15px;
}


@media (max-width: 1089px) {
  
    .container, .filter{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: auto;
        margin-top: 0;
    }
    .container .addBtn,
    .container .dropdown{
        width: 100%;
    }
    .header{
        text-align: center;
    }
    
}
  