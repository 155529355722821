.conditionWrapper {
  width: 100%;
  margin-block: 20px;
  .conditionRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    img {
      margin-inline-end: 5px;
    }
    p{
      margin-bottom: 0;
    }
  }
}
