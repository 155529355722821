@import "../abstracts/colors";

.wrapper{
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 50px ;
}
.header {
  width: 100%;
  font-weight: 500;
  color: $black_dark;
  font-size: 30px;
  margin: 30px 0;
}
.cancelBtn p{
  font-size: 25px;
}
.checkBox{
  width: 33%;
}
.dropdownWrap,
.checkBoxWrap{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.checkBoxWrap{
  padding-bottom: 10px;
 }
  .gap{
    width: 10%;
  }
  .checkgap{
    width: 25%;
  }
  
.radioWrapper{
  width: 25%;
}

  @media (max-width: 1089px) {
      .wrapper {
        width: 40vw;
      }
      .dropdownWrap{
        flex-direction: column;
      }
    }
    
    @media (max-width: 817px) {
      .wrapper {
        width: 55vw;
      }
      .dropdownWrap,
      .checkBoxWrap{
        flex-direction: column;
      }
    } 
