@import '../abstracts/colors';

.wraper{
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 100%;
  background-color: transparent;
  margin-bottom: 10px;
}
label{
  margin-bottom: 10px;
}

input.inputBox {
  background-color: $grey_20;
  padding: 5px ;
  border: 0;
  height: 38px;
  cursor: pointer;
  position: relative;
}

input.inputBox:focus{
  border-color: $grey_basic;
}

.calendarElement {
  border: 1px solid #ccc;
  width: 100%;
  // position: absolute;
  // z-index: 66;
}