@import "../abstracts/colors";

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .form,
  .image {
    width: 50%;
    height: 100%;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    .formWrapper {
      flex: 1;
      display: flex;
      width: 60%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logoRow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block: 50px;
        p {
          color: $cyan_500;
          font-size: 24px;
        }
      }
      .header {
        width: 100%;
        font-weight: 500;
        color: $black_basic;
        font-size: 30px;
        margin-bottom: 30px;
        text-align: left;
      }
      .stepWrapper {
        width: 100%;
      }
      .cenetered {
        text-align: center;
      }
      .subHeader {
        font-size: 20px;
        font-weight: 300;
        color: $black_basic;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
}

.modalContent {
  border-radius: 40px;
  width: 80%;
  margin: auto;
  padding: 40px;
  position: relative;
  .modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .floatingBgImage {
      position: absolute;
      top: -40px;
      right: -40px;
    }
    .imageWrapper {
      z-index: 5;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-inline-end: 24px;
      margin-bottom: 50px;
    }
    .modalText {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    .form {
      width: 100%;
      .flexSection {
        width: 70%;
      }
    }
    .image {
      display: none;
    }
  }
}
