@import "../abstracts/colors";
.wrapper {
  width: 100%;
  margin-bottom: 15px;
  .inputWrapper {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $grey_100;
    border-radius: 14px;
    margin-bottom: 5px;
    position: relative;
    .iconWrapper {
      background-color: $grey_50;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      height: 100%;
      width: 13%;
    }
    input {
      flex: 1;
      appearance: none;
      outline: none;
      border: none;
      background-color: $grey_100;
      height: 100%;
      font-size: 20px;
      padding-inline: 15px;
      border-radius: 14px;
    }
    .eye {
      position: absolute;
      right: 15px;
      cursor: pointer;
    }
  }
  .errorWrapper {
    border: 1px solid $red_error;
  }
  .errorIcon {
    border-inline-end: 1px solid $red_error;
  }
  .errorMsg {
    color: $red_error;
    display: block;
  }
}
