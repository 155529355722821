@import "../abstracts/colors";

.wrapper{
  display: flex;
  width: 45vw;
  margin: 50px auto 100px auto;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  // margin-bottom: 0;

.header {
  width: 100%;
  font-weight: 500;
  color: $black_dark;
  font-size: 30px;
  margin: 30px 0;
}
.cancelBtn p{
  font-size: 25px;
}

}
.dropdownWrap,
.checkBoxWrap{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.checkBoxWrap{
padding-bottom: 10px;
}
.gap{
  width: 10%;
}
.checkgap{
  width: 20%;
}

@media (max-width: 1089px) {
    .wrapper {
      width: 40vw;
    }
    .dropdownWrap{
      flex-direction: column;
    }
  }
  
  @media (max-width: 817px) {
    .wrapper {
      width: 55vw;
    }
    .dropdownWrap,
    .checkBoxWrap{
      flex-direction: column;
    }
  }