@import "../abstracts/colors";

.wraper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
 
.messageWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

textarea{
    border: 1px solid $grey_500;
    border-radius: 18px;
    width: 100%;
    height: 150px;
    display: flex;
    padding: 15px;
    margin: .6em 0;
    resize: none;
    background-color: transparent;
    }
textarea:focus{
    outline: none ;
    border-color: $light_grey;
}
textarea::placeholder{
        font: normal normal normal 16px/19px Segoe UI;
        color:$light_grey;
    }
}
}
.messageLabel{
    align-content: flex-start;
    width: 100%;   

label{
    font: normal normal normal 22px ;
    color: $black_basic;
}
}
.errorMsg {
    color: $red_error;
    display: block;
}


@media (max-width: 1261px) {

    textarea{
        height: 100px !important;
    }
}