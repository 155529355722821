@import "../abstracts/colors";

.wraper{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    position: relative;
    cursor: pointer;
    width: 37%;
    padding-top: 7px;
}
.checkbox{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: 10px 10px 0 0;
    z-index: 555;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $cyan_300;
    background-color: transparent;
    border-radius: 4px;
    margin: 10px 0 ;
}

.wraper input:checked ~ .checkmark {
background-color: $cyan_300;
}

.wraper .checkmark:after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid $white_basic;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


input:checked ~ .checkmark:after{
    opacity: 1;
}

.infoWraper{
    display: flex;
    flex-direction: column;
    margin-left: 2.5em;
}

p{
    letter-spacing: 0px;
    font-size: 12px;
}
label{
    margin: 5px 0;
}


@media (max-width: 1089px) {
    .wraper{
        width: 100%;
    }
  }
  
  @media (max-width: 817px) {
    .wraper{
        width: 100%;
    }
  }